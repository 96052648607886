// ------------------------------------------------------------------------------
// ---------------------------------------------------------------------- Imports
// ------------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

import classNames from 'classnames'

import isNull from 'lodash/isNull'
import map from 'lodash/map'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Iframe from 'react-iframe'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { compiler } from 'markdown-to-jsx'

import Slider from '../slider'
import '../slider/style.less'

import Gallery from '../gallery'
import '../gallery/style.less'

import Audio from '../audio'
import '../audio/style.less'

import Video from '../video'
import '../video/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** BlockParser */
const BlockParser = ({ block, locale }) => {
  let { type, subType, lang, content, resources, resource, caption } = block

  if (isNull(lang)) {
    lang = 'en'
  }

  let returnThis = <Fragment />

  if (lang === locale) {
    if (type === 'space') {
      returnThis = <br />
    }

    if (type === 'carousel') {
      returnThis = <Slider resources={resources} />
    }

    if (type === 'text') {
      returnThis = (
        <p className={classNames(subType)}>
          {compiler(content, { wrapper: null })}
        </p>
      )
    }

    if (type === 'iframe') {
      returnThis = (
        <Iframe
          url={content}
          width="100%"
          height="100px"
          display="initial"
          position="relative"
          className={classNames('flow', 'frame', subType)}
        />
      )
    }

    if (type === 'title') {
      returnThis = <h2 className={classNames(subType)}>{content}</h2>
    }

    if (type === 'sub-title') {
      returnThis = <h2 className={classNames('as-h3', subType)}>{content}</h2>
    }

    if (type === 'image') {
      const image = getImage(resource)
      const isPortrait = subType === 'portrait' ? 'portrait' : ''

      if (isNull(caption) === false) {
        returnThis = (
          <div className={classNames('with-caption as-paragraph', subType)}>
            <GatsbyImage className={`${isPortrait}`} image={image} />
            <p className="caption">{caption}</p>
          </div>
        )
      } else {
        returnThis = (
          <GatsbyImage
            className={`cover as-paragraph ${isPortrait}`}
            image={image}
          />
        )
      }
    }

    if (type === 'gallery') {
      const images = []
      map(resources, ({ cover }) => {
        images.push({
          data: cover,
          height: cover.childImageSharp.gatsbyImageData.height,
          width: cover.childImageSharp.gatsbyImageData.width,
        })
      })

      returnThis = <Gallery className={classNames(subType)} images={images} />
    }

    if (type === 'video') {
      returnThis = <Video className={classNames(subType)} url={content} />
    }

    if (type === 'audio') {
      returnThis = <Audio className={classNames(subType)} url={content} />
    }
  }

  return returnThis
}

/** Wrapper */
const Wrapper = ({ block, locale }) => {
  const {
    type,
    subType = undefined,
    contentBlocks: childContentBlocks = {},
  } = block

  let returnThis = <Fragment />

  if (type === 'block') {
    returnThis = (
      <div className={classNames('block', 'as-paragraph', subType)}>
        <BlockParser block={childContentBlocks} locale={locale} />
      </div>
    )
  } else {
    returnThis = <BlockParser block={block} locale={locale} />
  }

  return returnThis
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default Wrapper
