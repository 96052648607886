// ------------------------------------------------------------------------------
// ---------------------------------------------------------------------- Imports
// ------------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** [description] */
const AudioSvgSymbol = (props) => (
  <svg
    width={900}
    height={900}
    viewBox="0 0 900 900"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h900v900H0z" />
      <g fill="#000">
        <path d="M566.408 367.5c-10.099-8.236-24.743-6.41-33.015 3.652-8.236 10.098-6.41 24.743 3.652 33.015 12.819 10.993 21.986 27.5 21.986 45.833 0 19.265-9.167 36.667-23.848 47.66-9.167 7.34-10.993 20.159-3.653 29.326l1.826 1.826c8.236 9.167 21.986 10.993 32.084 3.652 24.814-18.297 40.39-48.555 40.39-82.464 0-33.015-14.681-63.235-39.424-82.5h.002Z" />
        <path d="M620.518 301.51c-10.098-8.237-23.848-6.41-32.083 3.652-8.236 10.098-7.34 24.742 2.757 32.083 33.015 26.569 54.069 66.924 54.069 112.759 0 44.902-21.09 86.152-54.069 112.76-10.098 8.235-10.993 22.916-2.757 32.083 8.236 10.098 22.917 11.924 32.083 3.652 43.076-34.84 71.508-88.91 71.508-148.49-.931-59.584-28.432-113.65-71.508-148.492v-.008ZM429.842 265.732l-120.101 91.666h-58.652c-15.576 0-27.5 11.924-27.5 27.5v129.269c0 15.576 11.924 27.5 27.5 27.5h58.652l120.101 91.666c21.091 16.508 51.349.931 51.349-25.674l-.036-317.148c0-26.604-30.222-41.25-51.313-24.778v-.001Z" />
        <path d="M450 14.583C209.843 14.583 14.583 209.843 14.583 450c0 240.158 195.26 435.417 435.417 435.417 240.158 0 435.417-195.26 435.417-435.417 0-240.157-195.26-435.417-435.417-435.417Zm0 803.019c-202.602 0-367.602-165-367.602-367.602 0-202.602 165-367.602 367.602-367.602 202.602 0 367.602 165 367.602 367.602 0 202.602-165 367.602-367.602 367.602Z" />
      </g>
    </g>
  </svg>
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default AudioSvgSymbol
